@import "../assets/scss/core/abstracts/_mixins.scss";

.container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-size: cover;
  // background-image: url(https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80);
  @include illustration("backgroundcheveuxnappy.png", 100%, 100%, center);
  // style="background-image:url(https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80);"
}

.background {
  // "absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: #e2e8f0;
  background-color: #1a202c;
  opacity: 0.55;
}

.comingSoonText {
  // ="z-10 text-sm"
  z-index: 10;
  font-size: 0.875rem;
}

.countdownWrapper {
  // flex items-end justify-center z-10
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 10;
}

.digit {
  // "text-indigo-600 font-bold text-xl sm:text-5xl"
  color: indigo;
  font-weight: bold;
  font-size: 3rem;
  @media screen and (max-width: 600px) {
    font-size: 4rem;
  }
}

.digitWrapper {
  margin: 1.25rem;
}

.btnWrapper {
  border-radius: 0.375rem;
  margin-top: 1.25rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  // background-color: #667eea;
  background-color: rgba(102, 126, 234, 1);
  border-radius: 3rem;
  z-index: 10;
}

.btn {
  //  px-8 py-3 border border-transparent text-base leading-6 font-light rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-md md:px-16"
  width: 100%;
  // padding: 0.75rem 2rem;
  padding: 1rem 4rem;
  color: white;
  line-height: 3rem;
  text-decoration: inherit;
  span {
    color: white;
  }
  // border: ;
}
